label {
  cursor: pointer;

  sup {
    top: -1px;
    @apply text-sm;
      color: #a63d2a;
  }

  input[type=radio] {
    display: none;

    + span {
      position: relative;
      @apply flex;
      @apply gap-x-2;

      &:before {
        content: "\e93f";
        border-radius: 50%;
        display: block;
        font-size: 20px;
        margin-top: -1px;
        height: 18px;
        width: 18px;
        @apply font-icomoon;
      }
    }

    &:checked {
      + span {
        @apply text-black;

        &:before {
          content: '\e933';
          @apply text-green;
        }
      }
    }
  }

  input[type="checkbox"] {
    display: inline-block;
    vertical-align: -2px;
  }
}
