html {
  scroll-behavior: smooth;
}

hr {
  @apply bg-gray-dark;
}

textarea {
  min-height: 90px;
}

// Custom container
.container-sm {
  max-width: 570px;
}

.container-md {
  max-width: 770px;
  margin: auto;
}

.container-lg {
  max-width: 1000px;
  margin: auto;
}

.container-xl {
  max-width: 1280px;
  margin: auto;
}

.m-0 {
  margin: 0px !important;
}

.ml-145px {
  margin-left: 145px !important;
}

.ml-135px {
  margin-left: 135px !important;
}

// Gradients

// Gradient blue to indigo
.bg-gradient-bl-to-in {
  background: linear-gradient(244deg, #D1D5FA -7%, #A9DFE2 122.83%);
}

// Gradient indigo dark
.bg-gradient-in-dark {
  background: linear-gradient(357.92deg, #6F88FC -23.65%, #D1D5FA 122.67%);
}

// Gradient indigo chat
.bg-gradient-in-chat {
  background: linear-gradient(244deg, #6F88FC -6.98%, #A9DFE2 114.63%);
}

.bg-brown {
  background: #602606;
}

.bg-red {
  background: #DF1919;
}

.h-color-gradient {
  background: linear-gradient(359.6deg, #6F88FC 0.32%, #D1D5FA 87.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}


// Shadows
.form-shadow {
  box-shadow: 0px 0px 60px 20px rgba(165, 141, 111, 0.1);
}


// colours

.color-indigo {
  color: #6F88FC;
}

.color-light-gray {
  color: #D0D2D9;
}

.color-black {
  color: #000000;
}

.color-gray {
  color: #888B93;
}

.bg-light-gray {
  background-color: #D0D2D9;
}

.bg-purple {
  background-color: #6F88FC;
}

.bg-beige {
  background: #F7F0E7;
}
.border-neutral-hover {
    border: 1px solid #dcdde0;
}

.border-gray {
  border-bottom-color: #D0D2D9
}

.border-light-gray {
  border-color: #D0D2D9
}

.border-1-light-gray {
  border: 1px solid #D0D2D9;
}

.border-b-1-dark-gray {
  border-bottom: 1px solid #888B93;
}

.border-b-1-light-gray {
  border-bottom: 1px solid #D0D2D9;
}

.border-t-1-dark-gray {
  border-top: 1px solid #888B93;
}

.text-blue {
  color: #6F88FC;
}

.text-dark-gray {
  color: #888B93;
}

// common

.nowrap {
  white-space: nowrap;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.mt-100 {
  margin-top: 100px;
}

.mt-37 {
  margin-top: 9.25rem;
}

.mb-23 {
  margin-bottom: 6.5rem;
}

.mb-34 {
  margin-bottom: 8.5rem;
}

.mb-150 {
  margin-bottom: 150px;
}

.mx-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.br-top-05 {
  border-radius: 0.5rem 0.5rem 0rem 0rem;
}

.br-bottom-05 {
  border-radius: 0rem 0rem 0.5rem 0.5rem;
}

.br-05 {
  border-radius: 0.5rem;
}

.br-12 {
  border-radius: 12px;
}

.br-circle {
  border-radius: 50%;
}

.border-top-none {
    border-top: none !important;
}

.h-42px {
  height: 42px !important;
}

.h-78px {
  height: 78px !important;
}

.h-90 {
  height: 90px !important;
}

.h-220px {
  height: 220px;
}

.w-5percent {
  width: 5%;
}

.w-10percent {
  width: 10%;
}

.w-15percent {
  width: 15%;
}

.w-25percent {
  width: 25%;
}

.w-30percent {
  width: 30%;
}

.w-35percent {
  width: 35%;
}

.w-45percent {
  width: 48%;
}

.w-50percent {
  width: 50%;
}

.w-30 {
  width: 30px;
}

.w-60 {
  width: 60px;
}

.w-90 {
  width: 90px;
}

.w-130 {
  width: 130px;
}

.w-270 {
  width: 270px;
}

.w-100 {
  width: 100%;
}

.max-w-80p {
  max-width: 80%;
}

.max-w-180 {
  max-width: 180px;
}

.max-w-770 {
  max-width: 770px;
}

.w-170px {
  width: 170px;
}

.w-235px {
  width: 235px;
}

.w-270px {
  width: 270px;
}

.w-300px {
  width: 300px;
}

.w-370px {
  width: 370px;
}

.w-390px {
  width: 390px;
}

.w-630px {
  width: 630px;
}

.w-670px {
  width: 670px;
}

.w-970px {
  width: 970px;
}

.w-fill {
  width: -webkit-fill-available;
  width: -moz-available;
}

.w-inherit {
  width: inherit;
}

.min-w-200px {
  min-width: 200px;
}

.br-45 {
  border-radius: 45px;
}

.line-h-20 {
  line-height: 20px !important;
}

.line-h-32 {
  line-height: 32px;
}

.line-h-38 {
  line-height: 38px;
}

.line-h-58 {
  line-height: 58px;
}

.description-text {
  font-size: 14px;
  font-weight: 400;
}

.step-buttons {
  color: #000000;
}

.step-buttons:hover {
  color: #6F88FC;
}

.rating-star-solid {
  color: #FFC700;
}

.rating-star {
  color: #D0D2D9;
}




.dropdown-shadow {
  box-shadow: 0px 0px 60px 8px rgba(165, 141, 111, 0.18);
}


// Text size
.font-16px {
  font-size: 16px;
}

.font-18px {
  font-size: 18px;
}

.font-24px {
  font-size: 24px;
}

.font-28px {
  font-size: 28px;
}

.text-600 {
    font-weight: 600;
}

.text-400-11-12 {
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
}

.text-400-11-14 {
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
}

.text-400-12-16 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.text-400-14-20 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@media (max-width: 640px) {
    .xs\:text-400-14-20 {
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 20px !important;
    }
}

.text-400-16-20 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.text-400-16-24 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.text-400-18-26 {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}

.text-400-30-20 {
  font-weight: 400;
  font-size: 30px;
  line-height: 20px;
}

.text-600-10-12 {
    font-weight: 600;
    font-size:10px;
    line-height: 12px;
}
.text-600-12-16 {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
}


.text-600-16-24 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

@media (max-width: 640px) {
    .xs\:text-600-16-24 {
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 24px !important;
    }
}

.text-600-18-26 {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}

.text-600-14-20 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.text-600-20-26 {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}

.text-600-20-28 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
}

@media (max-width: 640px) {
    .xs\:text-600-20-28 {
        font-weight: 600 !important;
        font-size: 20px !important;
        line-height: 28px !important;
    }
}

.text-600-20-46 {
  font-weight: 600;
  font-size: 20px;
  line-height: 46px;
}

.text-600-24-46 {
  font-weight: 600;
  font-size: 24px;
  line-height: 46px;
}

.text-600-24-30 {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
}

.text-600-24-32 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
}

.text-600-32-46 {
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;
}

.text-600-32-40 {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}

.text-700-14-20 {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.text-700-14-46 {
  font-weight: 700;
  font-size: 14px;
  line-height: 46px;
}

.text-700-16-20 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.text-600-44-46 {
  font-weight: 600;
  font-size: 44px;
  line-height: 46px;
}

.text-600-62-64 {
  font-weight: 600;
  font-size: 62px;
  line-height: 64px;
}

.weight-600 {
  font-weight: 600;
}

.square-image-select {
    display: flex;
    width: 120px;
    height: 120px;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f7;
    input[type="file"] {
        opacity: 0;
        visibility: hidden;
        height: 0;
        width: 0;
    }
}

// Video

.youtube-video {
  width: 100%;
  height: 500px;
}

.filter-box {
  span {
    color: black;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  label input[type=checkbox]+span:before {
    color: #888B93;
  }

  label {
    padding-top: 12px;
  }
}

// label input[type=checkbox] {
//   display: none;
// }

// label input[type=checkbox]+span {
//   position: relative;
//   display: flex;
//   -moz-column-gap: 0.5rem;
//   column-gap: 0.5rem;
// }

// label input[type=checkbox]+span:before {
//   content: "\e9e1";
//   border-radius: 50%;
//   display: block;
//   font-size: 20px;
//   margin-top: -1px;
//   height: 18px;
//   width: 18px;
//   font-family: icomoon;
// }

// label input[type=radcheckboxio]:checked+span {
//   --tw-text-opacity: 1;
//   color: rgb(0 0 0 / var(--tw-text-opacity));
// }

// label input[type=checkbox]:checked+span:before {
//   content: "\e934";
//   --tw-text-opacity: 1;
//   color: rgb(123 223 157 / var(--tw-text-opacity));
// }

.slider-text {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.custom-range {
  -webkit-appearance: none;
  height: 2px;
  background: #D0D2D9;
  border: 0px;
  background-image: linear-gradient(#6F88FC, #6F88FC);
  background-size: 50% 100%;
  background-repeat: no-repeat;
}

/* Input Thumb */
.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #6F88FC;
  cursor: ew-resize;
}

.custom-range::-moz-range-thumb {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #6F88FC;
  cursor: ew-resize;
}

.custom-range::-ms-thumb {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #6F88FC;
  cursor: ew-resize;
}

.icon-color-hover {
  i {
    @apply text-dark-gray;
  }

  i:hover {
    @apply text-blue;
  }
}

.custom-range-disabled {
  -webkit-appearance: none;
  height: 2px;
  background: #D0D2D9;
  border: 0px;
  background-image: linear-gradient(#000000, #000000);
  background-size: 50% 100%;
  background-repeat: no-repeat;
}

.custom-range-disabled::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #000000;
}

/* Autocomplete */
.autocomplete-results {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(208 210 217 / var(--tw-border-opacity));
}

.autocomplete-result {
  cursor: pointer;
  @apply p-3;
}

.autocomplete-result:hover {
  background-color: rgb(111, 136, 252, 0.25);
}

.error-message {
  font-size: 0.75rem;
  line-height: 1rem;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.science-video-img {
  width: -webkit-fill-available;
  object-fit: cover;
}

.pricing-box {
  margin: 10px;
  display: flex;
  justify-content: space-between;
}


input[type=range]::-webkit-slider-thumb {
  pointer-events: all;
  width: 8px;
  height: 8px;
  -webkit-appearance: none;

  /* @apply w-6 h-6 appearance-none pointer-events-auto; */
}

.price-range-text {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.vertical-line {
  width: 1px;
  height: 16px;
  background-color: #000000;
  margin: 0 22px;
}

.custom-search-field {
  position: relative;
}

.custom-search-field i {
  position: absolute;
  right: 1rem;
  top: 0.7rem;
  font-weight: 400;
  font-size: 1.5rem;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-align-center {
  text-align: center;
}

.text-align-end {
  text-align: end;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: start !important;
}

.align-block-center {
  margin: 0 auto;
}

.pac-container {
  z-index: 10000 !important;
}

.checkbox-lock+span:before {
  content: url("/img/icon-unlock.svg") !important;
  margin-top: 7px !important;
}

.checkbox-lock:checked+span:before {
  content: url("/img/icon-lock.svg") !important;
}

.sticky-header {
  @apply sticky;
  @apply bg-beige;
  top: 0px;
}

.product-details-icon {
  position: absolute;
  top: calc(50% - 34px);
  right: calc(50% - 26px);
  color: #FFFFFF;
  display: none;
  text-align: center;

  i {
    font-size: 32px;
  }
}

.featured-product-mark {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  background: linear-gradient(220deg, #6F88FC 0%, #A9DFE2 100%);
  box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.20);

  span {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }
}

.product-img {
  transition: .3s all ease-in-out;
}

.product-box {
  max-width: 245px;
  padding-top: 1.5rem;
  padding-bottom: 1rem;

  img {
    object-fit: cover;
    height: 220px;
    width: 245px;
  }

  &:hover {
    .product-details-icon {
      display: flex;
    }

    .product-img {
      @apply brightness-50;
    }
  }

  .icon-plus-big {
    display: inline-block;
    transform: rotate(0);
    transition: transform .3s ease-in-out;

    &:hover {
      transform: rotate(180deg);
    }
  }
}

.featured-box {
  padding-top: 0px;
}

.modal-dropdown {
  max-width: 720px;
}

.active-icon {
  --tw-text-opacity: 1;
  color: rgb(111 136 252 / var(--tw-text-opacity));
}

.offcanvas.show {
  visibility: visible;
}

.w-270 {
  width: 270px;
}

.max-w-170 {
  max-width: 170px !important;
}

.max-h-130 {
  max-height: 130px !important;
}

.min-h-40 {
  min-height: 40px !important;
}
.min-h-375 {
  min-height: 375px !important;
}

.max-w-370 {
  max-width: 370px !important;
}

.max-w-450 {
  max-width: 450px !important;
}

.max-w-470 {
  max-width: 470px !important;
}

.max-w-570 {
  max-width: 570px !important;
}

.max-h-250 {
  max-height: 250px;
}

.max-h-300 {
  max-height: 300px !important;
}

.max-h-500 {
  max-height: 500px;
}

.min-w-140 {
  min-width: 140px !important;
}

.btn-list:hover {
  @apply bg-white;
}

.top-16 {
  margin-top: 16px !important;
}

.pagination {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 400;
}

.page-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  // width: 36px;
}


.page-item:first-child,
.page-item:last-child {
  background-color: #FFFFFF;
  color: #D0D2D9;
  font-size: 32px;
  display: flex;
  align-items: center;
}

// .page-item:nth-child(3) {
//   position: relative;
// }

// .page-item:nth-child(3) a:before, .page-item:nth-child(3) span:before {
//   content: 'of';
//   visibility: visible;
//   display: block;
//   position: absolute;
//   margin-left: -20px;
//   margin-right: 20px;
//   font-weight: 400;
// }

.page-item:first-child span,
.page-item:last-child span,
.page-item:first-child a,
.page-item:last-child a {
  margin-bottom: 4px;
}

.page-item.active {
  font-weight: 700;
}

.fit-cover {
  object-fit: contain;
}

.upload-icon {
  position: relative;
  top: 40%;
}

.formatted-message {
  border-width: 1px;
  border-radius: 0.5rem;
  padding: 10px;
}

.bnt-gray {
  background: #D0D2D9;
  border-color: #D0D2D9;
  cursor: not-allowed;
}

.bnt-gray:hover {
  background: #D0D2D9;
  border-color: #D0D2D9;
}

.owl-dot span {
  background: #FFFFFF !important;
}

.owl-dot.active span {
  background: #6F88FC !important;
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 2.75rem !important;
  padding-bottom: 2.75rem !important;
}

.w-50percent {
  width: 50%;
}

.text-600-18-24 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.support_block {
  height: 541px;
  background: var(--gradient, linear-gradient(224deg, #6F88FC 0%, #A9DFE2 100%));
  display: flex;
  flex-direction: row;
  margin-bottom: 156px;
}

.support_text_block {
  width: 50%;
  padding-top: 181px;
  padding-left: 136px;
}

.support_form_box {
  position: absolute;
  width: 570px;
  top: 100px;
  left: 12px;
  padding: 32px;
  background-color: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0px 0px 60px 20px rgba(0, 0, 0, 0.04);
}

.mt--5 {
  margin-top: -5px !important;
}

.sub-menu {
  position: absolute;
  top: 180px;
  width: 1170px;
  height: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 100px;
  border: none;
  background: rgba(255, 255, 255, 0.80);
  backdrop-filter: blur(4px);
  transition: .5s;
  -webkit-transition: .5s;
  -moz-transition: .5s;
}

.sub-menu-sticky {
  position: sticky !important;
  top: 97px !important;
  width: 100% !important;
  border-radius: 0 !important;
}

.how-it-works-block {
  height: 297px;
  background: var(--gradient, linear-gradient(224deg, #6F88FC 0%, #A9DFE2 100%));
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;
  align-items: center;
  text-align: center;
}

.how-it-works-text-block {
  margin: 0 auto;
}

.how-to-get-started-block {
  margin: 0 auto;
  width: 1170px;
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;
  align-items: center;
  text-align: center;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.index_menu {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom-width: 1px;
  @apply sub-menu;
  @apply z-10;
}

@media (max-width: 1250px) {
  .index_menu {
    // width: 500px;
    width: calc(100% - 50px);
    // left: 50px;
    // right: auto;
  }
}

.how-it-works-arrow {
  height: fit-content;
  padding-top: 30px;
  margin-left: 20px;
}

.centered-element {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plan-box {
  max-width: 370px !important;
}

.plans-box {}

@media (max-width: 1200px) {
  .plans-box {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .plans-box .plan-box {
    max-width: none !important;
    padding: 0px 10px 20px 10px;
  }
}

@media (max-width: 640px) {
  .xs\:pt-8 {
    padding-top: 2rem;
  }

  .xs\:pt-16 {
    padding-top: 4rem;
  }
}

.min-w-1170px {
  min-width: 1170px;
}

.waitlist {
  min-height: 100%;
}

.waitlist-poster {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.wl-1 {
  background-image: url("/img/waitlist-1.png");
}

.wl-2 {
  background-image: url("/img/waitlist-2.png");
}

.wl-3 {
  background-image: url("/img/waitlist-3.png");
}

.waitlist-text {
  padding-left: 115px;
  padding-right: 135px;
}

.waitlist-title {
  font-weight: 600;
  font-size: 62px;
  line-height: 64px;
}

.waitlist-input {
  background-color: inherit;
}

.waitlist-input::placeholder {
  color: #000000;
}

@media (max-width: 1400px) {
  .waitlist {
    display: block;
    grid-template-columns: none;
    min-height: auto;
  }

  .waitlist-poster {
    height: 300px;
  }

  .wl-1 {
    background-image: url("/img/waitlist-mob-1.png");
  }

  .wl-2 {
    background-image: url("/img/waitlist-mob-2.png");
  }

  .wl-3 {
    background-image: url("/img/waitlist-mob-3.png");
  }

  .waitlist-text {
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 40px;

    img {
      width: 160px;
      margin-bottom: 32px;
    }

    .waitlist-title {
      font-weight: 600;
      font-size: 42px;
      line-height: 46px;
    }
  }
}

.item-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form-error {
  color: #DF1919;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-top: 4px;
}

.form-success {
  color: #6F88FC;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.modal-autocomplete {
  max-width: 405px;
}

#register input:disabled,
#registerVendor input:disabled {
  @apply bg-light-gray;
}

.pb-34 {
  padding-bottom: 8.5rem;
}

// ---------------
/* Custom SVG icons */
// ---------------
.svg-icon {
  display: block;
  width: 24px;
  height: 24px;
  background: #D0D2D9;

  &.active {
    background: #6F88FC;
  }
}

a:hover {
  .svg-icon {
    background: #6F88FC;
  }
}

.svg-icon-noimage {
  mask: url("/img/icons/noimage.svg");
  background: #6F88FC;
}

.svg-icon-building-church {
  mask: url("/img/icons/building-church.svg");
  background: #888B93;
}

.noimage-box {
  width: 32px;
  height: 32px;
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: #EEF1FF;
}

.page-link {
  i {
    font-size: 20pt;
    height: 20px;
    display: flex;
    justify-content: center;
    justify-items: center;
  }
}

.of {
  width: 10px;
}

.activity_link {
  i {
    font-size: 15pt;
    height: 20px;
    display: flex;
    justify-content: center;
    justify-items: center;
  }
}

#edit-funeral {
  input {
    border-color: #D0D2D9;
  }

  .select2-container--default .select2-selection--multiple {
    height: 45px;
    border-radius: 0;
    border-color: #D0D2D9;
  }
}

.btn:disabled {
  background-color: #D0D2D9;
  border-color: #D0D2D9;
}

.order-info-box {
  input {
    border-color: #D0D2D9;
  }
}

.radio-control {
    input[type="radio"] {
        color: #6f6fd2;
        border-radius: 100%;
        outline: none;
        box-shadow: none;
    }
    label > input[type="radio"] + *::before {
        content: "";
        display: inline-block;
        vertical-align: bottom;
        width: 1rem;
        height: 1rem;
        margin-right: 0.3rem;
        border-radius: 50%;
        border-style: solid;
        border-width: 0.1rem;
        border-color: gray;
    }
    label > input[type="radio"]:checked + *::before {
        background: radial-gradient(#6F6FD2 0%, #6F6FD2 40%, transparent 50%, transparent);
        border-color: #6F6FD2;
        content: '';
    }
    label {
        input[type=radio] + span {
            column-gap: 0;
        }
    }
}

#lifetime-map {
  height: 500px;
  width: 100%;
}

[x-cloak] {
  display: none;
}