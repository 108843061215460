.ys-audio-player {
  @apply flex w-full rounded-full p-1;
  background-color: rgba(242, 243, 245, 0.70);

  &-play-button {
    @apply cursor-pointer h-[34px] w-[34px] text-white rounded-full flex items-center justify-center flex-none;
    @apply bg-purple-600;
  }

  &-controls {
    @apply flex justify-between;
  }

  &-volume {
    @apply flex items-center;

    > input {
      @apply absolute w-20 h-3 left-3 hidden;
    }

    &-icon {
      @apply cursor-pointer;
    }

    &-slider {
      @apply cursor-pointer w-full outline-none bg-transparent appearance-none;
    }
  }

  .volume {
    &:hover {
      > input {
        @apply block;
      }
    }
  }
}

.ys-audio-player-volume input[type="range"]::-webkit-slider-thumb {
  @apply appearance-none h-1 w-1 bg-white rounded-full border-none;
  -webkit-appearance: none;
}
.ys-audio-player-volume input[type="range"]::-moz-range-thumb {
  @apply appearance-none h-1 w-1 bg-white rounded-full border-none;
  -webkit-appearance: none;
}
.ys-audio-player-volume input[type="range"]::-ms-thumb {
  @apply appearance-none h-1 w-1 bg-white rounded-full border-none;
  -webkit-appearance: none;
}
.ys-audio-player-volume input[type="range"]::-webkit-slider-runnable-track {
  @apply h-1 w-full bg-black rounded-md;
}
.ys-audio-player-volume input[type="range"]::-ms-track {
  @apply h-1 w-full bg-black rounded-md text-transparent bg-transparent border-transparent;
}

.audio-player-volume input[type="range"]::-webkit-slider-thumb {
  @apply appearance-none h-1 w-1 bg-white rounded-full border-none;
  -webkit-appearance: none;
}
.audio-player-volume input[type="range"]::-moz-range-thumb {
  @apply appearance-none h-1 w-1 bg-white rounded-full border-none;
  -webkit-appearance: none;
}
.audio-player-volume input[type="range"]::-ms-thumb {
  @apply appearance-none h-1 w-1 bg-white rounded-full border-none;
  -webkit-appearance: none;
}
.audio-player-volume input[type="range"]::-webkit-slider-runnable-track {
  @apply h-1 w-full bg-black rounded-md;
}
.audio-player-volume input[type="range"]::-ms-track {
  @apply h-1 w-full bg-black rounded-md text-transparent bg-transparent border-transparent;
}

@keyframes wave {
  0%, 100% {
      transform: scaleY(1);
  }
  50% {
      transform: scaleY(1.2);
  }
}

.svg-wave rect {
  animation: wave 1.5s infinite ease-in-out;
  transform-origin: bottom;
}

.svg-wave rect:nth-child(odd) {
  animation-delay: 0.1s;
}

.svg-wave rect:nth-child(even) {
  animation-delay: 0.2s;
}

input.chat-video-volume[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 4px;
  background: transparent;
  cursor: pointer;
}
input.chat-video-volume[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 8px;
  height: 8px;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
}
input.chat-video-volume[type=range]:focus {
  outline: none;
}