@import './components/audio-player';
@import './components/cover-block';

$hovered_white: #f5f5f5;
$hovered_purple: rgb(111 111 210 / 0.08);
$purple_strong: #6F6FD2;
$purple_strong_hover: #5959A8;
$purple_strong_pressed: #43437E;
$purple_light_hover: rgba(111, 111, 210, 0.25);
$purple_light_pressed: rgba(111, 111, 210, 0.35);

*{
    box-sizing: border-box;
}

:root{
    background: #F7F7F6;
}

html {
    background-color: #F7F7F6!important;
}

.knowledge-block{
    textarea{
        min-height: auto;
        border: none;
        padding: 0;
    }
}

.redactor {
    &-toolbar{
        display: flex;
        background-color: #E7E7E9;
        border-radius: 16px 16px 0px 0px;
        padding: 8px;
        padding-bottom: 24px;
        justify-content: space-between;

        &-buttons{
            display: flex;
            gap: 4px;

            .toolbar-button{
                width: 32px;
                height: 32px;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &.main{
                    background-color: $purple_strong;

                    &:hover{
                        border: none;
                        background: $purple_strong_hover;
                    }
                    &:active{
                        background: $purple_strong_pressed;
                    }
                }

                &:hover:not(.main){
                    background-color: #FFFFFF;
                }
                &:active:not(.main){
                    background-color: $hovered_white;
                }
            }
        }
    }

    &-action-btn{
        width: 32px;
        height: 32px;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #DCDDE0;

        &:hover{
            background-color: $hovered_white;
        }
    }

    &-body{
        min-height: 85px;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        margin-top: -16px;
        padding: 32px 32px 32px 24px;
        gap: 16px;
        border-radius: 16px;
        box-shadow: 0px 4px 16px 0px #181B2F0A;
        border: 1px solid #E7E7E9;

        .redactor-row{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 8px;
            padding-right: 32px;

            // this is because of calendar
            &:first-of-type{
                padding-right: 120px;
            }

            .redactor-action-btn{
                width: 24px;
                height: 24px;
                border: none;
            }

            .editor {
                flex: 1;
                overflow-y: auto;
                color: black;
                display: flex;
                align-items: center;

                h1, h2, h3, h4, h5, h6 {
                    margin: 0;
                    //line-height: 1.1;
                }
                .inline{
                    display: inline-block;
                    margin-right: 5px;
                }
            }
            .editor:focus {
                outline: none;
            }
        }
    }

    .image-section{
        display: flex;
        flex-direction: column;
        gap: 12px;

        &.grid{
            display: grid;
            gap: 8px;
        }

        .image-preview{
            position: relative;
            display: flex;
            height: 335px;
            padding: 16px;
            justify-content: space-between;
            align-items: flex-start;
            border-radius: 16px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: #F7F7F6;

            &.small{
                cursor: pointer;
                border-radius: 8px;
                height: 140px;
                justify-content: center;
                align-items: center;
                .icon-button{
                    margin-left: auto;
                    margin-bottom: auto;
                }
            }

            &.audio{
                cursor: pointer;
                border-radius: 30px;
                height: 60px;
                justify-content: center;
                align-items: center;
            }

            .secondary-button{
                z-index: 2;
            }

            .icon-button{
                z-index: 2;
                margin-left: 10px;
            }

            .video-js{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }

            .safari-message{
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                flex-direction: column;
                gap: 10px;
                text-align: center;
                position: absolute;
                padding: 20px 70px;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                h3{
                    font-size: 22px;
                }
                p{}
            }
        }

        // slick slider
        .slider-container{
            height: 48px;

            .slick-slider{
                $padding: 12px;
                $preview_image_height: 48px;
                $arrow_size: 28px;

                .slick-list{
                    left: $arrow_size + $padding;
                    width: calc( 100% - ($arrow_size * 2) - ($padding * 2) );
                }

                .slick-slide {
                    cursor: pointer;
                    height: 48px;
                    margin: 0 6px;

                    background: #F6F6F7;
                    transition: opacity 0.2s ease-in-out;

                    &.slick-cloned{
                        opacity: 0;
                    }

                    &.slick-active{
                        opacity: 1;
                    }

                    img{
                        border-radius: 4px;
                        width: 100%;
                        height: $preview_image_height;
                        object-fit: cover;
                    }

                    video{
                        object-fit: cover;
                        width: 100%;
                        height: $preview_image_height;
                    }
                }

                .slick-arrow{
                    display: flex !important;
                    justify-content: center;
                    align-items: center;
                    height: $arrow_size;
                    width: $arrow_size;
                    border-radius: 7px;
                    border: 1px solid #DCDDE0;

                    &:before {
                        content: none;
                    }

                    &.slick-prev, &.slick-next{
                        top: 50%;
                        transform: translate(0, -50%);
                    }

                    &.slick-prev{
                        left: 0;
                    }

                    &.slick-next{
                        right: 0;
                    }
                }
            }

        }
    }

    blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre{
        margin: 0;
    }
}

.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    background: white;
}

.floating-toolbar {
    z-index: 1000;
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #E7E7E9;
    background: white;
    box-shadow: 0px 4px 8px 2px rgba(24, 27, 47, 0.12), 0px 2px 4px 0px rgba(24, 27, 47, 0.12);

    display: flex;
    flex-direction: row;

    &-item{
        padding: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 8px;
        background: transparent;
        border: none;
        outline: none;

        &:hover{
            background: $hovered_purple;
        }
        &:focus-visible, &:focus{
            outline: none;
        }
    }

    .divider{
        margin: 0 4px;
        width: 1px;
        height: 100%;
        background: #E7E7E9;
    }
}

.custom-dropdown {
    position: fixed;
    z-index: 1000000;
    width: 263px;
    padding: 8px 0;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #DCDDE0;
    background: white;
    box-shadow: 0px 2px 8px 0px rgba(24, 27, 47, 0.10);

    &.nested{
        pointer-events: none;
        opacity: 0;
        transform: translateY(15px);
        transition: 0.3s ease-in-out;
        left: 100%;
        top: 0;
        position: absolute;
    }

    .divider{
        margin: 6px 0;
        width: 100%;
        height: 1px;
        background: #E7E7E9;
    }

    &-item {
        position: relative;
        display: flex;
        cursor: pointer;
        padding: 8px 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;

        p {
            margin: 0;
            color: #181B2F;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        .more-items{
            margin-left: auto;
            height: 16px;
        }

        &:hover{
            background: $hovered_purple;

            .custom-dropdown.nested {
                pointer-events: auto;
                opacity: 1;
                transform: translateY(-7px);
            }
        }
    }
}

.tiptap {
    color: black;
    border: none;
    width: 100%;

    &:focus, &:focus-visible {
        outline: none;
    }

    :first-child:not(hr) {
        margin-top: 0;
    }

    /* List styles */
    ul li{
        list-style: disc;
    }
    ol li{
        list-style: auto;
    }

    ul,
    ol {
        margin: 0.25rem 0.4rem;
        padding: 0 15px;
        //overflow: none;

        li{
            //overflow: auto;
            margin-bottom: 8px;
            &:last-of-type{
                margin-bottom: 0;
            }
        }

        li p {
            //margin-top: 0.25em;
            //margin-bottom: 0.25em;
        }

        &[data-type="taskList"] {
            margin: 0 0 1.25rem;
            padding-left: 0;

            li {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                align-items: center;

                p{
                    margin: 0;
                }

                & *, &::after, &::before {
                    box-sizing: border-box;
                }

                label:before {
                    content: "";
                    display: inline-block;
                    vertical-align: top;
                    height: 1.15em;
                    width: 1.15em;
                    margin-right: 0.3em;
                    color: rgba(0, 0, 0, 0.275);
                    border: solid 0.06em;
                    box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em inset, 0 0 0 0.07em transparent inset;
                    border-radius: 0.2em;
                    background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="white" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>') no-repeat center, white;
                    background-size: 0;
                    will-change: color, border, background, background-size, box-shadow;
                    transform: translate3d(0, 0, 0);
                    transition: color 0.1s, border 0.1s, background 0.15s, box-shadow 0.1s;
                }

                &[data-checked="true"]{
                    label:before {
                        background-color: #3B99FC;
                        background-size: 0.75em;
                        color: rgba(0, 0, 0, 0.075);
                    }
                    label:before,
                    label:active:before {
                        background-color: #0a7ffb;
                        color: rgba(0, 0, 0, 0.275);
                    }
                }

                & input[type=checkbox] {
                    margin: 0;
                    width: 0;
                    height: 0;
                    display: inline;
                    opacity: 0;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }
            }
        }
    }

    /* Heading styles */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
        margin-top: 2.5rem;
        color: #181B2F;
        margin-bottom: 16px;

        &:last-child{
            margin-bottom: 0;
        }
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
    }

    h3 {
        font-size: 19px;
    }

    h4 {
        font-size: 18px;
    }

    h4,
    h5,
    h6 {
        font-size: 17px;
    }

    p{
        font-family: Inter;
        color: #565967;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    blockquote {
        border-left: 3px solid #3d25141f;
        //margin: 1.5rem 0;
        padding-left: 1rem;
    }

    hr {
        border: none;
        border-top: 1px solid #3d25141f;
        margin: 12px 0;
    }

    a{
        text-decoration: underline;
    }

}

.custom-modal-root{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding: 30px 0;
    left: 0;
    top: 0;
    z-index: 1000000;
    background: rgba(0, 0, 0, 0.2);

    p{
        margin-bottom: 0;
    }

    .custom-modal{
        width: 450px;
        max-width: 90%;
        max-height: calc(100vh - 60px);
        margin: 30px 15px;
        overflow: auto;

        border-radius: 12px;
        border: 1px solid #E7E7E9;
        background: #F7F7F6;

        display: flex;
        padding: 16px 0;
        flex-direction: column;
        align-items: flex-start;
        box-shadow: 0px 16px 20px 6px rgba(24, 27, 47, 0.12), 0px 4px 8px 0px rgba(24, 27, 47, 0.12);
    }
}

.add-block-modal{
    .modal-content{
        flex: 1;
        overflow: auto;
        gap: 16px;
        display: flex;
        flex-direction: column;
    }

    .section{

        .section-label{
            padding: 0 24px;
            color: #565967;

            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 12px; /* 120% */
            letter-spacing: 0.2px;
            text-transform: uppercase;
        }

        .section-item{
            align-items: center;
            padding: 8px 24px;
            cursor: pointer;
            &:hover {
                background: white;
            }

            .icon-wrapper{
                width: 72px;
                height: 52px;
                display: flex;
                padding: 10px 20px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 8px;

                border-radius: 8px;
                background: rgba(111, 111, 210, 0.08);
            }
        }
    }

    .modal-footer{
        flex: 1;
        padding: 16px 24px;
        .section-label{
            padding: 0;
        }
    }
}

.add-block-btn{
    display: flex;
    gap: 32px;
    align-items: center;

    .lines{
        flex: 1;
        height: 1px;
        background: #E7E7E9;
    }
}

.droppzone{
    cursor: pointer;
    display: flex;
    padding: 16px;
    align-items: center;
    text-align: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px dashed #DCDDE0;
    background: #F6F6F7;

    &.large{
        min-height: 335px;
        padding: 81px 50px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 16px;
        flex-direction: column;
    }
}

.uploaded-files-root{
    display: flex;
    flex-direction: column;
    gap: 4px;

    .uploaded-file{
        border-radius: 8px;
        border: 1px solid #E7E7E9;
        background: #fff;
        gap: 8px;
        min-height: 39px;
        align-items: center;

        &.uploading{
            border-radius: 2px;
            min-height: 44px;
        }

        &.preview{
            cursor: pointer;
        }

        .icon-wrapper{
            cursor: pointer;
            width: 32px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 0;

            &:hover{
                background: $hovered_purple;
            }
        }

        .progress{
            height: 8px;

            border-radius: 25px;
            border: 1px solid rgba(111, 111, 210, 0.25);
            background: rgba(111, 111, 210, 0.08);

            div{
                height: 8px;
                border-radius: 25px;
                background: $purple_strong;
            }
        }
    }
}

.popover-container {
    position: relative;
    display: inline-block;
}

.popover-trigger {
    cursor: pointer;
}

.popover-content {
    position: absolute;
    transform: translateX(-50%);
    background-color: white;
    border-radius: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000;
}

// TEXT

.body-r14 {
    color: #181B2F;

    /* Body/14 [SB] */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
}

.body-r12 {
    color: #565967;

    /* Body/12 [R] */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}

.title-h4{
    color: #181B2F;

    /* Title/H4 [SB] */
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}

// BUTTONS, INPUTS

input{ /* Chrome, Safari, Opera */
    &::-webkit-input-placeholder { /* Chrome, Safari, Opera */
        color: black!important;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: black!important;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: black!important;
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
        color: black!important;
    }
    &::placeholder { /* Standard */
        color: black!important;
    }
}

textarea{
    &::-webkit-input-placeholder { /* Chrome, Safari, Opera */
        color: #565967!important;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: #565967!important;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: #565967!important;
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
        color: #565967!important;
    }
    &::placeholder { /* Standard */
        color: #565967!important;
    }
}


button, input, textarea{
    &:focus-visible, &:focus, &:focus-within{
        outline: none !important;
    }
}

.main-button{
    display: flex;
    width: fit-content;
    padding: 10px 20px;
    gap: 8px;

    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: rgba(111, 111, 210, 0.08);
    border: none;
    color: $purple_strong;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;

    &.purple{
        color: #fff;
        background: $purple_strong;

        &:hover{
            border: none;
            background: $purple_strong_hover;
        }
        &:active{
            background: $purple_strong_pressed;
        }
    }


    &:hover{
        border: none;
        background: $purple_light_hover;
    }
    &:active{
        background: $purple_light_pressed;
    }
}

.icon-button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 24px;
    height: 24px;

    border-radius: 100px;
    border: 1px solid rgba(111, 111, 210, 0.25);
    background: white;
    backdrop-filter: blur(20px);

}

.secondary-button {
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    gap: 4px;
    height: 33px;
    align-self: stretch;
    border-radius: 8px;
    border: none;
    background: white;

    &.purple{
        color: #fff;
        background: $purple_strong;

        &:hover{
            border: inherit;
            background: $purple_strong_hover;
        }
        &:active{
            background: $purple_strong_pressed;
        }
    }

    &.light-purple{
        color: $purple_strong;
        background: rgba(111, 111, 210, 0.08);
    }

    &.error{
        color: #fff;
        background: #D04C35;
    }

    &.white{
        color: #000;
        border: 1px solid #DCDDE0;
        background: #FFF;

        &:hover{
            border: 1px solid #C5C6CB;
            background: #F6F6F7;
        }
        &:active{
            border: 1px solid #C5C6CB;
            background: #DCDDE0;
        }
    }

    @extend .body-r14
}

.label-button{
    display: flex;
    height: 32px;
    gap: 8px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: rgba(111, 111, 210, 0.08);

    color: #181B2F;

    /* Body/12 [R] */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}

// video player

.video-js {
    $primary-foreground-color: #fff;
    $primary-background-color: #E7E7E9;

    font-size: 10px;
    color: $primary-foreground-color;

    video {
        overflow: hidden;
        border-radius: 16px;
    }
    button.vjs-big-play-button{
        border: none;
        width: 1.63332em;
        height: 1.63332em;
        border-radius: 100%;
        transform: translateX(50%);
    }

    div.vjs-control-bar{
        margin-bottom: 5px;
        background-color: transparent;

        .vjs-play-control{
            background: rgb(93 93 93 / 30%);
            border-radius: 100%;
            padding: 0;
            width: 30px;
            height: 30px;
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    .vjs-progress-control.vjs-control{
        .vjs-progress-holder{
            height: 6px;
            border-radius: 24px;
            background: $primary-background-color;

            .vjs-load-progress{
                height: 6px;
                overflow: hidden;
                border-radius: 24px;
                background: $primary-background-color;

                div{
                    background: $primary-background-color;
                }
            }

            .vjs-play-progress{
                height: 6px;
                border-radius: 24px;
                background: $purple_strong;

                &::before{
                    content: url('../../js/redactor/icons/player-tracker.svg');
                    top: -9px;
                    right: -12px;
                }
            }
        }
    }
}

.video-recorder-root, .audio-recorder-root{
    .header {
        margin-bottom: 16px;

        .start-over-btn{
            display: flex;
            padding: 4px 8px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            color: #181B2F;
            font-weight: 600;
            border-radius: 6px;
            background: white;
            border: 1px solid #DCDDE0;
        }
    }

    .video-player {
        position: relative;
        width: 450px;
        height: 280px;

        &::before{
            content: ' ';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 100%;
            height: 280px;
            border-radius: 16px;
            background: url('../../js/redactor/icons/record-video-preview.png') lightgray -3px -1.266px / 101.333% 104.444% no-repeat;
        }

        &.recorded{
            height: 320px;
        }

        .video-js{
            z-index: 2;
            width: 100%!important;
            height: 100%!important;
            background-color: transparent;

            .vjs-play-control{
                top: -13px;
            }

            .vjs-control-bar{
                height: 6px;
                margin-bottom: 0;
            }
        }
        .vjs-modal-dialog{
            height: 280px;
            border-radius: 16px;
        }

        video{
            position: relative;
            z-index: 2;
            width: 100%;
            height: 280px;
            border-radius: 16px;

            object-fit: cover;

            &.hide{
                display: none;
            }
        }

        .recorded{}

        .live-player{

        }
    }

    .video-controls {
        margin-top: 32px;
        p{
            color: #575757;
            font-variant-numeric: lining-nums tabular-nums;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 114.286% */
            //opacity: 0.66;
        }
    }
}

div.react-calendar {
    border: none;
}

div.react-calendar__navigation{
    button{
        color: #000
    }
}

.react-calendar__month-view__{

    &weekdays__weekday, &days__day{
        color: #000;
        &.react-calendar__tile--active{
            color: white;
        }
    }

    &days__day--neighboringMonth{
        color: #757575
    }

    &days__day--weekend{
        color: #d10000
    }
}

.autocomplete {
    .selected-item {
        background-color: rgba(111,111,210,.08);
        border-radius: 15px;
        padding: 5px 10px;
        display: flex;
        align-items: center;
    }

    .selected-item button {
        background: none;
        border: none;
        margin-left: 5px;
        cursor: pointer;
    }

    &-dropdown {
        position: fixed;
        z-index: 1000001;
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #fff;
        max-height: 200px;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .dropdown-item {
            padding: 7px 10px;
            cursor: pointer;
            border-radius: 8px;
        }

        .dropdown-item:hover {
            background-color: #f0f0f0;
        }
    }
}


// PRIORITY TO TAILWIND
@tailwind base;
@tailwind components;
@tailwind utilities;


